import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import "./registration.css";
import Background from "../common/background/background";
import { useTranslation } from "react-i18next";
import RegistrationCheckboxes from "./checkbox/registration-checkboxes";
import CustomButton from "../common/button/custom-button";
import { usePinInput } from "react-pin-input-hook";
import CustomInput from "../common/input/custom-input";
import MobilePhoneSelect from "./mobile-phone-select/mobile-phone-select";
import ConfirmEmail from "./confirm-email/confirm-email";
import DataPage from "./data-page/data-page";
import ConfirmMobile from "./confirm-mobile/confirm-mobile";
import {
	clearAccount,
	createNewAccount,
	finishRegistration,
	getConfirmationCode,
	sendEulaCheckboxes,
	verifyConfirmationCode,
} from "../../api/api";
import CircleProgress from "../circle-progress/circle-progress";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { useTelegram } from "../../hooks/useTelegram";
import { Whale } from "../../icons/whale";
import {
	TCountry,
	TEulaCheckBox,
	TOriginalRegistrationCheckBox,
	TTheme,
} from "../../types/types";
import { ThemeContext } from "../../data/theme-provider";
import { FirebaseContext } from "../../data/firebase-provider";
import { Helmet } from "react-helmet";
import { Titles } from "../../types/titles";

const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";

const Registration = () => {
	const { initializeFirebase, sendAnalytics } = useContext(FirebaseContext);
	const { theme, updateTheme } = useContext(ThemeContext);
	const [email, setEmail] = useState<string>("");
	const [isRequired, setIsRequired] = useState<boolean>(false);
	const [isConfirmEmail, setIsConfirmEmail] = useState<boolean>(false);
	const [isChecked, setIsChecked] = useState<boolean[]>([false, false, false]);
	const [values, setValues] = useState<string[]>(["", "", "", "", "", ""]);
	const [isResendCodeActive, setIsResendCodeActive] = useState<boolean>(true);
	const [counter, setCounter] = useState<number>(0);
	const [isMobilePhoneSelect, setIsMobilePhoneSelectPhoneSelect] =
		useState<boolean>(false);
	const [phone, setPhone] = useState<string>("");
	const [isPhoneRequired, setIsPhoneIsRequired] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const inputPropsRef = useRef<any>();
	const [isLoginButtonShow, setIsLoginButtonShow] = useState<boolean>(false);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			if (values.join("").length == 6) {
				inputPropsRef.current.focus();
			}
		},
		onComplete: () => {
			if (values.join("").toString().length == 6) {
				inputPropsRef.current.focus();
			}
		},
		placeholder: "",
	});

	const [countryCode, setCountryCode] = useState<{
		code: string;
		imgUrl: string;
	}>({
		code: "+",
		imgUrl: "",
	});
	const [isDataPage, setIsDataPage] = useState<boolean>(false);
	const [name, setName] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [isNameRequired, setIsNameRequired] = useState<boolean>(false);
	const [isPasswordRequired, setIsPasswordRequired] = useState<boolean>(false);
	const [isConfirmMobile, setIsConfirmMobile] = useState<boolean>(false);
	const [checkBoxes, setCheckBoxes] = useState<
		{
			checkBoxId: string;
			isRequired: boolean;
		}[]
	>();
	const [state, setState] = useState<string>("");
	const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
	const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(false);
	const [countries, setCountries] = useState<TCountry[]>();
	const [eulaCheckboxes, setEulaCheckboxes] = useState<TEulaCheckBox>();
	const [isConfirmVerification, setIsConfirmVerification] = useState<boolean>(false);
	const buttonRef = useRef(null);

	const [isError, setIsError] = useState<boolean>(false);
	const [errorText, setErrorText] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

	const { t } = useTranslation();
	const { tg } = useTelegram();
	const navigate = useNavigate();

	const initData = async () => {
		await initializeFirebase();
		updateTheme();
		sessionStorage.setItem(
			"userToken",
			new URLSearchParams(window.location.search).get("token") as string
		);
		setIsLoading(true);
		await createNewAccount(
			sessionStorage.getItem("userToken") as string,
			setIsMobilePhoneSelectPhoneSelect,
			setIsDataPage,
			setEmail,
			setPhone,
			setState,
			setIsError,
			setErrorText,
			setIsRequired,
			setIsPhoneIsRequired,
			setCheckBoxes,
			setIsEmailVerified,
			setIsPhoneVerified,
			setCountries,
			sendAnalytics,
			setEulaCheckboxes
		);
		setIsLoading(false);
	};

	useEffect(() => {
		if (counter == 0) {
			setIsResendCodeActive(true);
			setCounter(60);
		}
		if (isConfirmEmail || isConfirmMobile) {
			const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return () => clearInterval(timer as any);
		}
	}, [counter, isConfirmEmail, isConfirmMobile]);

	useEffect(() => {
		initData();
	}, []);

	if (isLoading || !checkBoxes || !eulaCheckboxes) {
		return <CircleProgress thickness={6} size={40} />;
	}

	const handleLoginClickFromRegistration = async () => {
		setIsButtonLoading(false);
		navigate(
			`/${locale}/login?token=${
				sessionStorage.getItem("userToken") as string
			}&email=${email}`
		);
	};

	const handleBackClickFromRegistration = async () => {
		setIsButtonLoading(true);
		await clearAccount(state, sessionStorage.getItem("userToken") as string);
		setTimeout(() => {
			tg.close();
		}, 1000);
		//navigate(`/${locale}/login?token=${userToken}`);
	};

	const handleResendCode = async () => {
		setIsResendCodeActive(false);
		setIsError(false);
		setErrorText("");
		setCounter(60);
		if (isConfirmEmail) {
			await getConfirmationCode(
				state,
				"email",
				email,
				true,
				setIsError,
				setErrorText,
				setIsButtonLoading,
				sessionStorage.getItem("userToken") as string,
				setState,
				setIsEmailVerified,
				setIsPhoneVerified,
				setIsLoginButtonShow,
				sendAnalytics
			);
		}
		if (isConfirmMobile) {
			await getConfirmationCode(
				state,
				"phone",
				countryCode.code + phone.replaceAll(" ", ""),
				false,
				setIsError,
				setErrorText,
				setIsButtonLoading,
				sessionStorage.getItem("userToken") as string,
				setState,
				setIsEmailVerified,
				setIsPhoneVerified,
				setIsLoginButtonShow,
				sendAnalytics
			);
		}
	};

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		setIsError(false);
		if (e.target.value.length >= 100) {
			setIsError(true);
			setErrorText("registrationEmailLengthError");
			setEmail(e.target.value.substring(0, 100));
			e.target.value.length >= 1 ? setIsRequired(true) : setIsRequired(false);
			return;
		}
		setEmail(e.target.value);
		e.target.value.length >= 1 ? setIsRequired(true) : setIsRequired(false);
	};

	const handlePhoneChange = (e: string | ChangeEvent<HTMLInputElement>) => {
		if (typeof e === "string") {
			setPhone(e as string);
			String(e).length >= 1
				? setIsPhoneIsRequired(true)
				: setIsPhoneIsRequired(false);
		}
	};

	const handleCountryCodeChange = (value: { code: string; imgUrl: string }) => {
		setCountryCode({
			code: value.code,
			imgUrl: value.imgUrl,
		});
	};

	const handlePhoneClearClick = () => {
		setPhone("");
		setIsPhoneIsRequired(false);
	};

	const handleEmailClearClick = () => {
		setEmail("");
		setIsRequired(false);
	};

	const handleSubmit = async () => {
		setIsError(false);
		setErrorText("");
		if (isEmailVerified) {
			setIsMobilePhoneSelectPhoneSelect(true);
			return;
		}
		await sendEulaCheckboxes(
			checkBoxes as TOriginalRegistrationCheckBox[],
			isChecked,
			state,
			email,
			sessionStorage.getItem("userToken") as string,
			setIsError,
			setErrorText,
			setState,
			setIsButtonLoading,
			setIsResendCodeActive,
			setIsConfirmEmail,
			setCounter,
			setIsEmailVerified,
			setIsPhoneVerified,
			setIsLoginButtonShow,
			sendAnalytics
		);
	};

	const handleBackClickFromConfirmEmail = () => {
		setIsError(false);
		setErrorText("");
		setValues(["", "", "", "", "", ""]);
		setIsConfirmEmail(false);
	};

	const handleConfirmEmail = async () => {
		setIsError(false);
		setErrorText("");
		await verifyConfirmationCode(
			state,
			"email",
			email,
			values.join("").toString(),
			setIsError,
			setErrorText,
			setIsButtonLoading,
			setIsMobilePhoneSelectPhoneSelect,
			setValues,
			setIsConfirmEmail,
			true,
			setIsConfirmMobile,
			setIsDataPage,
			setCounter,
			sendAnalytics,
			setIsEmailVerified
		);
	};

	const handleConfirmMobile = async () => {
		setIsError(false);
		setErrorText("");
		if (isPhoneVerified) {
			setIsMobilePhoneSelectPhoneSelect(false);
			setIsDataPage(true);
			return;
		}
		setIsButtonLoading(true);
		await getConfirmationCode(
			state,
			"phone",
			countryCode.code + phone.replaceAll(" ", ""),
			false,
			setIsError,
			setErrorText,
			setIsButtonLoading,
			sessionStorage.getItem("userToken") as string,
			setState,
			setIsEmailVerified,
			setIsPhoneVerified,
			setIsLoginButtonShow,
			sendAnalytics,
			setIsMobilePhoneSelectPhoneSelect,
			setIsConfirmMobile,
			setCounter
		);
	};

	const handleConfirmMobileCode = async () => {
		setIsError(false);
		setErrorText("");
		await verifyConfirmationCode(
			state,
			"phone",
			countryCode.code + phone.replaceAll(" ", ""),
			values.join("").toString(),
			setIsError,
			setErrorText,
			setIsButtonLoading,
			setIsMobilePhoneSelectPhoneSelect,
			setValues,
			setIsConfirmEmail,
			false,
			setIsConfirmMobile,
			setIsDataPage,
			setCounter,
			sendAnalytics,
			setIsEmailVerified,
			setIsPhoneVerified
		);
	};

	const handleBackClickFromConfirmMobile = () => {
		setIsError(false);
		setErrorText("");
		setCounter(60);
		setIsResendCodeActive(false);
		setIsMobilePhoneSelectPhoneSelect(false);
	};

	const handleBackClickFromConfirmMobileCode = () => {
		setIsError(false);
		setErrorText("");
		setIsConfirmMobile(false);
		setCounter(60);
		setIsMobilePhoneSelectPhoneSelect(true);
	};

	const handleBackClickFromDataPage = () => {
		setIsError(false);
		setErrorText("");
		setIsDataPage(false);
		setIsMobilePhoneSelectPhoneSelect(true);
		setName("");
		setPassword("");
		setIsPasswordRequired(false);
		setIsNameRequired(false);
	};

	const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
		e.target.value.length >= 1 ? setIsNameRequired(true) : setIsNameRequired(false);
	};

	const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
		e.target.value.length >= 1
			? setIsPasswordRequired(true)
			: setIsPasswordRequired(false);
	};

	const handleNameClearClick = () => {
		setName("");
		setIsNameRequired(false);
	};

	const handlePasswordClearClick = () => {
		setPassword("");
		setIsPasswordRequired(false);
	};

	const handleConfirmDataPage = async () => {
		setIsError(false);
		setErrorText("");
		await finishRegistration(
			state,
			password,
			name,
			'',
			setIsButtonLoading,
			setIsError,
			setErrorText,
			setIsDataPage,
			navigate,
			sessionStorage.getItem("userToken") as string,
			locale,
			email,
			t,
			setIsConfirmVerification,
			sendAnalytics
		);
	};

	const handleConfirmVerification = () => {
		setTimeout(() => {
			navigate(
				`/${locale}/verification?token=${
					sessionStorage.getItem("userToken") as string
				}&email=${email}&password=${password}`,
				{
					replace: true,
					state: {
						email: email,
						userToken: sessionStorage.getItem("userToken") as string,
						password: password,
						name: name,
					},
				}
			);
			setIsConfirmVerification(false);
		}, 2000);
	};

	if (isConfirmEmail) {
		return (
			<>
				<Helmet>
					<title>{Titles.REGISTRATION.EMAIL}</title>
				</Helmet>
				<ConfirmEmail
					inputPropsRef={inputPropsRef}
					email={email}
					isResendCodeActive={isResendCodeActive}
					handleResendCode={handleResendCode}
					counter={counter}
					handleConfirmEmail={handleConfirmEmail}
					handleBackClickFromConfirmEmail={handleBackClickFromConfirmEmail}
					fields={fields}
					isError={isError}
					errorText={errorText}
					isLoading={isButtonLoading}
				/>
			</>
		);
	}

	if (isMobilePhoneSelect) {
		return (
			<>
				<Helmet>
					<title>{Titles.REGISTRATION.PHONE}</title>
				</Helmet>
				<MobilePhoneSelect
					isPhoneVerified={isPhoneVerified}
					isPhoneRequired={isPhoneRequired}
					phone={phone}
					handlePhoneChange={handlePhoneChange}
					handlePhoneClearClick={handlePhoneClearClick}
					handleConfirmMobile={handleConfirmMobile}
					handleBackClickFromConfirmMobile={handleBackClickFromConfirmMobile}
					countryCode={countryCode}
					handleCountryCodeChange={handleCountryCodeChange}
					isLoading={isButtonLoading}
					errorText={errorText}
					isError={isError}
					countries={countries as TCountry[]}
				/>
			</>
		);
	}

	if (isConfirmMobile) {
		return (
			<>
				<Helmet>
					<title>{Titles.REGISTRATION.PHONE_CODE}</title>
				</Helmet>
				<ConfirmMobile
					countryCode={countryCode}
					phone={phone.replaceAll(" ", "-")}
					fields={fields}
					isResendCodeActive={isResendCodeActive}
					handleResendCode={handleResendCode}
					counter={counter}
					handleConfirmMobileCode={handleConfirmMobileCode}
					handleBackClickFromConfirmMobileCode={
						handleBackClickFromConfirmMobileCode
					}
					isLoading={isButtonLoading}
					isError={isError}
					inputPropsRef={inputPropsRef}
					errorText={errorText}
				/>
			</>
		);
	}

	if (isDataPage) {
		return (
			<>
				<Helmet>
					<title>{Titles.REGISTRATION.DATA}</title>
				</Helmet>
				<DataPage
					isNameRequired={isNameRequired}
					name={name}
					handleNameChange={handleNameChange}
					handleNameClearClick={handleNameClearClick}
					isPasswordRequired={isPasswordRequired}
					handlePasswordChange={handlePasswordChange}
					password={password}
					handlePasswordClearClick={handlePasswordClearClick}
					handleConfirmDataPage={handleConfirmDataPage}
					handleBackClickFromDataPage={handleBackClickFromDataPage}
					isLoading={isButtonLoading}
					isError={isError}
					errorText={errorText}
				/>
			</>
		);
	}

	if (isConfirmVerification) {
		return (
			<>
				<Helmet>
					<title>{Titles.REGISTRATION.SUMMARY}</title>
				</Helmet>
				<div
					className={"componentBorderless" + (theme === "dark" ? "-dark" : "")}
				>
					<div className={"innerComponent"}>
						<Whale className={"verificationWhale"} />
						<h1
							className={
								"verificationQuestion" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("registrationSuccess")}
						</h1>
						<h1
							className={
								"verificationTestDescriptionText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("registrationSuccessText")}
						</h1>
						<ul className={"verificationConfirmPageContainer"}>
							<li>
								<h1
									className={
										"verificationConfirmPageText" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{t("registrationConfirmPageFirstElement")}
								</h1>
							</li>
							<li>
								<h1
									className={
										"verificationConfirmPageText" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{t("registrationConfirmPageSecondElement")}
								</h1>
							</li>
						</ul>
						<CustomButton
							theme={theme as TTheme}
							text={t("verificationLogoText") as string}
							type={"submit"}
							onClick={handleConfirmVerification}
							className={"verificationStartTestButton"}
						/>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>{Titles.REGISTRATION.INDEX}</title>
			</Helmet>
			<div>
				<Background />
				<div className={"component" + (theme === "dark" ? "-dark" : "")}>
					<div className={"innerComponent"}>
						<h1 className={"welcomeText" + (theme === "dark" ? "-dark" : "")}>
							{t("registrationWelcomeText")}
						</h1>
						<CustomInput
							theme={theme as TTheme}
							isDisabled={isEmailVerified}
							isRequired={isRequired}
							type={"email"}
							id={"email"}
							label={t("registrationProvideEmail")}
							value={email}
							onChange={handleEmailChange}
							className={"emailInput"}
							onClearClick={handleEmailClearClick}
							inputPropsRef={buttonRef}
						/>
						{isError ? (
							<h1 className={"registrationEmailError"}>{t(errorText)}</h1>
						) : (
							<div className={"registrationEmailError"} />
						)}
						<RegistrationCheckboxes
							mappedEula={eulaCheckboxes}
							locale={locale}
							checked={isChecked}
							setIsChecked={setIsChecked}
						/>
						<CustomButton
							theme={theme as TTheme}
							buttonRef={buttonRef}
							text={t("registrationStart") as string}
							type={"submit"}
							onClick={handleSubmit}
							className={"startButton"}
							isDisabled={
								isChecked[0] != checkBoxes[0]?.isRequired ||
								isChecked[1] != checkBoxes[1]?.isRequired ||
								email.length === 0
							}
							isLoading={isButtonLoading}
						/>
						<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
							<Divider
								className={"divider" + (theme === "dark" ? "-dark" : "")}
							/>
						</div>
						{isLoginButtonShow ? (
							<CustomButton
								theme={theme as TTheme}
								isText={true}
								text={t("loginText") as string}
								onClick={handleLoginClickFromRegistration}
								className={"backButton"}
							/>
						) : (
							""
						)}
						<CustomButton
							theme={theme as TTheme}
							isBack={true}
							onClick={handleBackClickFromRegistration}
							className={"backButton"}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Registration;
