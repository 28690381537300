import React, { ChangeEvent, useContext, useRef } from "react";
import CustomInput from "../../common/input/custom-input";
import CustomButton from "../../common/button/custom-button";
import { Divider } from "@mui/material";
import "../registration.css";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../../data/theme-provider";
import { TTheme } from "../../../types/types";

interface Props {
	isNameRequired: boolean;
	name: string;
	handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
	handleNameClearClick: () => void;
	isPasswordRequired: boolean;
	handlePasswordChange: (e: ChangeEvent<HTMLInputElement>) => void;
	password: string;
	handlePasswordClearClick: () => void;
	handleConfirmDataPage: () => void;
	handleBackClickFromDataPage: () => void;
	isLoading: boolean;
	isError: boolean;
	errorText: string;
}
const DataPage = ({
	handleConfirmDataPage,
	handleNameChange,
	handleNameClearClick,
	handlePasswordClearClick,
	isNameRequired,
	isPasswordRequired,
	handleBackClickFromDataPage,
	name,
	handlePasswordChange,
	password,
	isLoading,
	isError,
	errorText
}: Props) => {
	const { theme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const buttonRef = useRef(null);

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"nameAndPassword" + (theme === "dark" ? "-dark" : "")}>
					{t("registrationName&Password")}
				</h1>
				<h1 className={"confirmCodeText" + (theme === "dark" ? "-dark" : "")}>
					{t("registrationProvideNameAndPass")}
				</h1>
				<CustomInput
					theme={theme as TTheme}
					isRequired={isNameRequired}
					type={"text"}
					id={"name"}
					label={t("registrationName")}
					value={name}
					onChange={handleNameChange}
					className={"nameInput"}
					onClearClick={handleNameClearClick}
					inputPropsRef={buttonRef}
				/>
				<CustomInput
					theme={theme as TTheme}
					isRequired={isPasswordRequired}
					type={"password"}
					id={"password"}
					label={t("registrationCreatePassword")}
					value={password}
					onChange={handlePasswordChange}
					className={"passwordInput"}
					onClearClick={handlePasswordClearClick}
					inputPropsRef={buttonRef}
					isPassword={true}
				/>
				<h1 className={"createPasswordHelp" + (theme === "dark" ? "-dark" : "")}>
					{t("registrationCreatePasswordHelp")}
				</h1>
				{isError ? (
					<h1 className={"registrationEmailError"}>{errorText}</h1>
				) : (
					<div className={"registrationEmailError"} />
				)}
				<CustomButton
					theme={theme as TTheme}
					buttonRef={buttonRef}
					isLoading={isLoading}
					text={t("registrationContinue") as string}
					type={"submit"}
					onClick={handleConfirmDataPage}
					className={"confirmEmailButton"}
					isDisabled={password.length == 0 || name.length == 0}
				/>
				<Divider className={"divider" + (theme === "dark" ? "-dark" : "")} />
				<CustomButton
					theme={theme as TTheme}
					isBack={true}
					onClick={handleBackClickFromDataPage}
					className={"backButton"}
				/>
			</div>
		</div>
	);
};

export default DataPage;
